<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="12" style="overflow-x: auto;">
                <zw-table ref="table"
                          tableName="workflow_triggers"
                          columnsPrefix="workflow_triggers.column."
                          base-table="workflow_triggers"
                          tableFormat="App\Managers\Format\WorkflowTriggersFormat"
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >
                    <template #cell(actions)="row">
                        <b-button size="sm"
                                  @click="$root.$children[0].openModal('workflow-trigger-modal', {
                        id: row.item.id,
                    }, refreshTable, {width: '800px'})"
                                  variant="primary"
                                  class="mr-2"
                        >
                            <font-awesome-icon icon="edit"/>
                        </b-button>
                    </template>
                </zw-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'WorkflowTriggers',
    mixins: [commonTable],
    data() {
        return {
            loading: true,
            saveFilters: true,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('CommonTable', ['getCommonTable']),
        shown() {
            this.loading = false
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CommonTable/fetchTableData', 'getCommonTable');
        },
        ctxBaseParams() {
            return {
                'url': '/table-data/workflow_triggers',
                'format': 'App\\Managers\\Format\\WorkflowTriggersFormat'
            }
        },
        getActions() {
            return [{
                title: this.$t('common.button.create'),
                icon: 'plus',
                click: () => {
                    this.$root.$children[0].openModal('workflow-trigger-modal', {
                        id: null,
                    }, this.refreshTable, {width: '800px'})
                }
            }]
        },
    }
}
</script>